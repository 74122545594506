import { render, staticRenderFns } from "./pointRecordList.vue?vue&type=template&id=1ef5e783&scoped=true"
import script from "./pointRecordList.vue?vue&type=script&lang=js"
export * from "./pointRecordList.vue?vue&type=script&lang=js"
import style0 from "./pointRecordList.vue?vue&type=style&index=0&id=1ef5e783&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ef5e783",
  null
  
)

export default component.exports