<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :extraParams="extraParams"
      :afterList="afterList"
      :isOperateColumn="false"
    >
      <template #headBtnSlot> </template>
      <template #searchSlot>
        <v-select
          clearable
          filterable
          :options="TenantList"
          v-model="searchParam.tenantId"
          @change="$refs.list.search()"
          label="租户"
        />
        <v-date-picker
          label="选择时间"
          v-model="createTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="创建开始时间"
          endPlaceholder="创建结束时间"
        />
        <div class="totalPoint">总积分：{{ totalPoint }}</div>
      </template>
    </v-list>
  </div>
</template>

<script>
import { pointTypeMap } from "./map";
import { pointsListUrl, getDTenantListUrl, getPointCountUrl } from "./api";

export default {
  name: "bonusPointSettingList",
  data() {
    let _this = this;
    return {
      searchParam: {
        startTime: undefined,
        endTime: undefined,
        tenantId: "",
      },
      TenantList: [],
      extraParams: {
        operationType: 1,
      },
      tableUrl: pointsListUrl,
      totalPoint: "",
      headers: [
        {
          prop: "operationType",
          label: "操作",
          align: "center",
          formatter: (row, prop) => {
            console.log(row[prop], "555");
            return row[prop] == 0 ? "减去" : "增加";
          },
        },
        {
          prop: "tokenPoint",
          label: "积分值",
        },
        {
          prop: "recordType",
          label: "积分类型",
          align: "center",
          formatter: (row, prop) => pointTypeMap[row[prop]],
        },
        {
          prop: "updateTime",
          label: "获取时间",
        },
        {
          prop: "userName",
          label: "用户名",
        },
      ],
    };
  },
  components: {},
  mounted() {
    this.getDTenantList();
  },
  computed: {
    createTime: {
      get() {
        return !this.searchParam.startTime && !this.searchParam.endTime
          ? []
          : [this.searchParam.startTime, this.searchParam.endTime];
      },
      set(val) {
        [this.searchParam.startTime, this.searchParam.endTime] = val || [
          "",
          "",
        ];
      },
    },
  },
  methods: {
    afterList() {
      this.getPointCount();
    },
    async getDTenantList() {
      let params = {};
      let res = await this.$axios.get(`${getDTenantListUrl}`, {
        params,
      });
      if (res.code === 200) {
        this.TenantList = res.data.map((item) => ({
          label: item.tenantName,
          value: item.tenantId,
        }));
      }
    },
    async getPointCount() {
      let params = {
        operationType: 1,
        startTime: this.searchParam.startTime,
        endTime: this.searchParam.endTime,
        tenantId: this.searchParam.tenantId,
      };
      let res = await this.$axios.get(`${getPointCountUrl}`, {
        params,
      });
      if (res.code === 200) {
        this.totalPoint = res.data;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
  .totalPoint {
    line-height: 44px;
    font-weight: 700;
  }
}
</style>
